import { supabase } from "./supabase";

loadNews();
loadDokumente();
loadSettings();

export async function loadNews() {
  const { data, error } = await supabase.from("news").select();
  localStorage.setItem("newsData", JSON.stringify(data));
}

export async function loadDokumente() {
  const { data, error } = await supabase.from("dokumente").select();
  localStorage.setItem("dokumenteData", JSON.stringify(data));
}

export async function loadSettings() {
  const { data, error } = await supabase.from("settings").select();
  localStorage.setItem("settingsData", JSON.stringify(data));
}
